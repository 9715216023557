.shopify-section.homepage-section--blog-posts-wrapper, .shopify-section.homepage-section--featured-collections-wrapper, .shopify-section.homepage-section--featured-products-wrapper, .shopify-section.homepage-section--image-gallery-wrapper, .shopify-section.homepage-section--logo-bar-wrapper, .shopify-section.homepage-section--instagram-wrapper, .shopify-section.homepage-section--text-and-image-wrapper, .shopify-section.homepage-section--indiv-product-wrapper, .shopify-section.homepage-section--rich-text-wrapper, .shopify-section.homepage-section--social-media-wrapper {
  padding-top: 60px;
  padding-bottom: 60px; }

.homepage-featured-content-wrapper {
  text-align: center; }

.homepage-featured-content-wrapper .title-bar {
  margin-bottom: 32px; }

.homepage-featured-content-wrapper .btn-custom {
  margin-top: 32px; }

.footer {
  padding-top: 60px;
  color: #ebebeb; }

.footer h3 {
  margin-top: 0;
  color: #ebebeb; }

.footer a {
  color: #ebebeb; }

.sod_select.focus, .sod_select .sod_select.custom.focus {
  box-shadow: none; }

.sod_select.custom.custom {
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  border-radius: 0px;
  box-shadow: none; }

.sod_select.custom::before, .sod_select.custom::after {
  border-left: 0; }

.sod_select.custom.custom {
  background: #fff;
  width: 100%; }

.sod_select.custom .sod_list_wrapper {
  width: 100%; }

.no-js:not(html) {
  display: none; }

.no-js .no-js:not(html) {
  display: block; }

.no-js .js {
  display: none; }

.product-form__variants {
  display: none; }

.no-js .product-form__variants {
  display: block; }

.notification-bar {
  font-size: 14px; }

@media (min-width: 992px) {
  .notification-bar {
    font-size: 16px; } }
.blog-main ul {
  padding-left: 0px; }

.blog-main ul li {
  list-style-type: none;
  margin-bottom: 40px; }

.blog-main ul li:last-child {
  margin-bottom: 0; }

#blog-tags-wrapper {
  margin-bottom: 20px; }

.product-grid .indiv-product-wrapper {
  margin-bottom: 30px; }

ul#product-thumbnails {
  padding-left: 0px;
  width: 100%; }

ul#product-thumbnails li {
  list-style-type: none;
  padding-top: 20px; }

ul#product-thumbnails li img {
  max-width: 100%; }

#sharing {
  margin-bottom: 20px;
  margin-top: 20px; }

#cart-total-header, #cart-total-final-price {
  font-size: 18px;
  text-transform: uppercase;
  display: inline-block;
  margin-bottom: 15px; }

#cart-total-header {
  font-weight: bold;
  margin-right: 10px; }

.action_delete a {
  color: inherit;
  text-decoration: none; }

.default_address {
  position: absolute;
  top: -1px;
  right: -1px;
  padding: 10px;
  background-color: #333;
  color: #fff;
  text-transform: uppercase;
  font-size: 12px;
  border-top-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem; }

/*# sourceMappingURL=theme.scss.liquid.map */
